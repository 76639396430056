.btn-hope {
  font-size: 20px;
  font-weight: 700;
}
.pDark {
  font-size: 16px;
  color: #ababab;
  font-weight: 700;
}
.pDetails {
  font-size: 14px;
  color: #ababab;
  font-weight: 500;
}
.collapseTab {
  font-size: 16px;
  font-weight: 700;
  color: #666666;
  padding: 20px;
  background: #f4f4f4;
}
.collapseDetails {
  font-size: 14px;
  color: #000;
  font-weight: 500;
}
.form-littlehope .form-control {
  background-color: #ababab;
  font-size: 14px;
  border-radius: 0;
}
.form-control:focus {
  border: 1px solid #026666;
  box-shadow: none;
  outline: 0 !important;
  color: #026666;
  background-color: #f7f7f7;
}
input::placeholder,
textarea::placeholder {
  color: #000 !important;
}

/* Appeal */
.subHead {
  font-size: 14px;
  font-weight: 400;
}
.appeal-title {
  font-size: 18px;
  font-weight: 700;
  color: #333;
}

.appeal-description {
  font-size: 14px;
  font-weight: 500;
  color: #666;
  margin-top: 20px;
  min-height: 140px;
}
.appeal-img {
  min-height: 170px;
  object-fit: cover;
}
/* Green Box */
.greenBox {
  background-color: #026666;
  position: relative;
  padding: 30px 34px 30px 50px;
}
.greenBox .gsubtitle {
  font-size: 22px;
  color: #fff;
  font-weight: 500;
  margin-bottom: 0;
}
.greenBox .gboxTitle {
  font-weight: 700;
  font-size: 29px;
  color: #ffde00;
  margin-bottom: 0;
}
.greenBox .gboxDetails {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  line-height: 32px;
}
/* Grey Box */
.greyBox {
  background-color: #ababab;
  position: relative;
  padding: 30px 34px 30px 50px;
}
.greyBox .gsubtitle {
  font-size: 22px;
  color: #fff;
  font-weight: 500;
  margin-bottom: 0;
}
.greyBox .gboxTitle {
  font-weight: 700;
  font-size: 29px;
  color: #026666;
  margin-bottom: 0;
}
.greyBox .gboxDetails {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  line-height: 32px;
}
/* White Box */
.whiteBox {
  background-color: #fff;
  position: relative;
  padding: 30px 34px 30px 50px;
}
.whiteBox .gsubtitle {
  font-size: 22px;
  color: #ababab;
  font-weight: 500;
  margin-bottom: 0;
}
.whiteBox .gboxTitle {
  font-weight: 700;
  font-size: 29px;
  color: #026666;
  margin-bottom: 0;
}
.whiteBox .gboxDetails {
  font-size: 16px;
  font-weight: 500;
  color: #ababab;
  line-height: 32px;
}
