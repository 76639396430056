/* Navbar */
.navbar {
  border-bottom: 1px solid #e3e3e3;
  box-shadow: none;
}
.navbar.navbar-transparent {
  background-color: #ffffff85 !important;
  box-shadow: none;
  color: #ffffff;
  padding-top: 20px !important;
}
.page-nav.navbar.navbar-transparent {
  background-color: #4a4d5170 !important;
}

.login h3 {
  color: #002147;
}

.login label {
  font-size: 18px;
  color: #002147;
}
.login .msg {
  font-size: 18px;
  color: #51586c;
}

.login .btn-info {
  background-color: #0094ff;
  font-size: 18px;
}
.text-green {
  color: #002147;
  font-weight: 500;
}
/* registration */

.registration h3 {
  color: #002147;
}

.registration label {
  font-size: 18px;
  color: #002147;
}
.registration .msg {
  font-size: 18px;
  color: #51586c;
}

.registration .btn-info {
  background-color: #0094ff;
  font-size: 18px;
}

/* .btn-green:after {
  content: "»";
  position: absolute;
  opacity: 0;
  top: 14px;
  left: 20px;
  transition: 0.5s;
}

.btn-green:hover {
  padding-right: 8px;
  padding-left: 24px;
}

.btn-green:hover:after {
  opacity: 1;
  left: 30px;
} */

/* hero */
.page-header .content-center-hero {
  position: absolute;
  top: 22%;
  z-index: 2;
  color: #ffffff;
  width: 100%;
  max-width: 880px;
}
h2.hero-title {
  margin-left: 22%;
}
h2.hero-title span {
  font-size: 70px;
  font-weight: 900;
}
.hero-color-overlay {
  background: linear-gradient(89deg, #ffffff73, transparent);
  padding-left: 22%;
  padding-bottom: 5%;
}

.btn-outline-info:hover {
  text-decoration: auto;
}

@media screen and (max-width: 400px) {
  .page-header .content-center-hero {
    position: relative;
    top: 0%;
    margin: 40% 0 40%;
  }
  h2.hero-title {
    margin-left: 2%;
    font-size: 25px;
  }
  h2.hero-title span {
    font-size: 50px;
    font-weight: 900;
  }
  p.hero-paragraph {
    font-size: 16px !important;
  }
  .hero-color-overlay {
    padding-bottom: 16%;
  }
  /* create campaign sidebar */

  .breadcrumb {
    margin-top: 40px;
  }
  .bd-toc-item > .bd-toc-link {
    font-size: 20px !important;
  }
  .bd-title {
    font-size: 30px !important;
  }
  .create-campaign .campTitle {
    font-size: 20px !important;
  }
}

/* startcampaign */
h3 {
  margin-bottom: 10px;
  line-height: 30px;
}
.campaign .parag,
label {
  font-size: 20px;
  font-weight: 400;
  color: #4f566a;
}
.campaign .bold {
  font-size: 20px;
  font-weight: 400;
  color: #002147;
}
.form-check-radio input[type="radio"]:checked + .form-check-sign::after {
  width: 12px;
  height: 12px;
  background-color: #2ca8ff;
  border-color: #2ca8ff;
  top: 7px;
  left: 7px;
}

.form-check-radio .form-check-sign::before,
.form-check-radio .form-check-sign::after {
  border-radius: 0;
  border: 1px solid #2ca8ff;
}

.campaign,
option {
  font-size: 20px;
  font-weight: 400;
  color: #51586c;
}
.campaign .input-group .form-control:first-child,
.input-group-text:first-child,
.input-group-btn:first-child > .dropdown-toggle,
.input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle) {
  border: 1px !important;
}
.campaign .camp-alert {
  font-size: 24px;
  font-weight: 400;
  color: #0094ff;
}

/* section-acheived */

.section-acheived h3.ach-title {
  font-size: 16px;
  padding: 0;
  line-height: 0;
}

/* section donate */
.page-header {
  /* background: rgb(55 40 40 / 46%); */
}
.page-header:before {
  background-color: rgb(0 0 0 / 0%);
}
.page-header.donate {
  min-height: 60vh !important;
  max-height: 400 px;
  padding: 0;
  position: relative;
  overflow: hidden;
}

/* campaign */

.title-camp {
  font-size: 40px;
  font-weight: bold;
  text-transform: uppercase;
  color: #fff;
  background: linear-gradient(88deg, #007bff, transparent);
  padding: 30px;
}
.section-campaign {
  margin-top: 5rem;
}
.section .single-campaign {
  background-color: #f4f4f4;
  padding: 60px 30px;
  border-bottom: 3px solid #2ca8ff;
}
.section-campaign .campaign-header {
  background-color: #f4f4f4;
  padding: 30px;
  margin: 30px 0;
}

.section-campaign .campaign-header h3 {
  margin: 0;
}

/* carousel slides */

.carousel-campaign .campaign-carousel-caption {
  position: absolute;
  bottom: 45%;
  padding-top: 20px;
  padding: 20px;
  color: #000;
}
.carousel-campaign h3 {
  margin-bottom: 0;
}
.campaign-meta {
  padding: 20px;
}

.carousel-campaign .now-ui-icons {
  background: #a09090;
  padding: 20px;
  border-radius: 50%;
}

.carousel-campaign .muted {
  color: #a3acb8;
  font-size: 20px;
  font-weight: 400;
}
.carousel-campaign .carousel .carousel-inner {
  box-shadow: 0px 10px 25px 0px rgb(255 255 255 / 0%);
  border: 1px solid grey;
  border-radius: 10px;
}

.carousel-campaign .carousel-control-prev {
  left: -15%;
}
.carousel-campaign .carousel-control-next {
  right: -15%;
}

/* Work */
/* campaign */

.title-camp {
  font-size: 40px;
  font-weight: bold;
  text-transform: uppercase;
  color: #fff;
  background: linear-gradient(88deg, #007bff, transparent);
  padding: 30px;
}
.section-work {
  margin-top: 1rem;
}
.section .single-campaign {
  background-color: #f4f4f4;
  padding: 60px 30px;
  border-bottom: 3px solid #2ca8ff;
}
.section-work .campaign-header {
  background-color: #f4f4f4;
  padding: 30px;
  margin: 30px 0;
}

.section-work .campaign-header h3 {
  margin: 0;
}

/* sidebar */

.bd-sidebar {
  order: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
@media (min-width: 768px) {
  .bd-sidebar {
    border-right: 1px solid rgba(0, 0, 0, 0.1);
  }
  @supports (position: sticky) {
    .bd-sidebar {
      position: sticky;
      top: 4rem;
      z-index: 1000;
      max-height: calc(100vh - 4rem);
    }
  }
}
@media (min-width: 1200px) {
  .bd-sidebar {
    max-width: 320px;
  }
}

.bd-links {
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-right: -15px;
  margin-left: -15px;
}
@media (min-width: 768px) {
  @supports (position: sticky) {
    .bd-links {
      max-height: calc(100vh - 4rem);
      overflow-y: auto;
    }
  }
}
.bd-links {
  display: block !important;
}

.bd-search {
  position: relative;
  padding: 1rem 15px;
  margin-right: -15px;
  margin-left: -15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.bd-search-docs-toggle {
  line-height: 1;
  color: #212529;
}

.bd-sidenav {
  display: none;
}

.bd-toc-link {
  display: block;
  padding: 0.25rem 1.5rem;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.65);
}
.bd-toc-link:hover {
  color: rgba(0, 0, 0, 0.85);
  text-decoration: none;
}

.bd-toc-item.active {
  margin-bottom: 1rem;
}
.bd-toc-item.active:not(:first-child) {
  margin-top: 1rem;
}
.bd-toc-item.active > .bd-toc-link {
  color: #303030;
  font-size: 18px;
  font-weight: bold;
  margin: 10px 15px;
  border-radius: 4px;
}
.bd-toc-item.active > .bd-toc-link i {
  margin-right: 20px;
}
.bd-toc-item.active > .bd-toc-link:hover {
  background-color: transparent;
}
.bd-toc-item.active > .bd-sidenav {
  display: block;
}

.bd-sidebar .nav > li > a {
  display: block;
  padding: 0.25rem 1.5rem;
  font-size: 90%;
  color: rgba(0, 0, 0, 0.65);
}

.bd-sidebar .nav > li > a:hover {
  color: rgba(0, 0, 0, 0.85);
  text-decoration: none;
  background-color: transparent;
}

.bd-sidebar .nav > .active > a,
.bd-sidebar .nav > .active:hover > a {
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  background-color: transparent;
}

/* content */

.bd-content {
  order: 1;
}
.bd-content > h2[id],
.bd-content > h3[id],
.bd-content > h4[id] {
  pointer-events: none;
}
.bd-content > h2[id] > div,
.bd-content > h2[id] > a,
.bd-content > h3[id] > div,
.bd-content > h3[id] > a,
.bd-content > h4[id] > div,
.bd-content > h4[id] > a {
  pointer-events: auto;
}
.bd-content > h2[id]::before,
.bd-content > h3[id]::before,
.bd-content > h4[id]::before {
  display: block;
  height: 6rem;
  margin-top: -6rem;
  visibility: hidden;
  content: "";
}
.bd-content > table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
}
@media (max-width: 991px) {
  .bd-content > table {
    display: block;
    overflow-x: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
}
.bd-content > table > thead > tr > th,
.bd-content > table > thead > tr > td,
.bd-content > table > tbody > tr > th,
.bd-content > table > tbody > tr > td,
.bd-content > table > tfoot > tr > th,
.bd-content > table > tfoot > tr > td {
  padding: 0.75rem;
  vertical-align: top;
  border: 1px solid rgba(0, 0, 0, 0.06);
}
.bd-content > table > thead > tr > th > p:last-child,
.bd-content > table > thead > tr > td > p:last-child,
.bd-content > table > tbody > tr > th > p:last-child,
.bd-content > table > tbody > tr > td > p:last-child,
.bd-content > table > tfoot > tr > th > p:last-child,
.bd-content > table > tfoot > tr > td > p:last-child {
  margin-bottom: 0;
}
.bd-content > table td:first-child > code {
  white-space: nowrap;
}

.bd-content > h2:not(:first-child) {
  margin-top: 3rem;
}

.bd-content > h3 {
  margin-top: 1.5rem;
}

.bd-content > ul li,
.bd-content > ol li {
  margin-bottom: 0.25rem;
}

@media (min-width: 992px) {
  .bd-content > ul,
  .bd-content > ol,
  .bd-content > p {
    max-width: 90%;
  }
}
/* Input */
.input-group .form-control:first-child,
.input-group-text:first-child,
.input-group-btn:first-child > .dropdown-toggle,
.input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle) {
  border-right: 1px solid #e3e3e3;
}
/* Breadcrumb */

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin-bottom: 1rem;
  list-style: none;
  background-color: rgba(255, 0, 0, 0);
  border-radius: 0;
}
.breadcrumb a,
.breadcrumb-item.active {
  color: #4f566a;
  font-size: 24px;
}

/* create campaign */
.bd-title {
  font-size: 30px;
  font-weight: bold;
  color: #002147;
}
.create-campaign .campTitle {
  color: #002147 !important;
  font-size: 18px;
}

/* story */
.camp-alert,
.camp-alert h3 {
  color: #a3acb8;
}
.camp-story-title {
  font-size: 32px;
}

.camp-story-description {
  font-size: 24px;
}

.camp-alert ul {
  list-style-type: none;
  padding: 0;
}
/* Responsive  */

/* @media screen and (max-width: 400px) {
  .page-header .content-center {
    top: 55%;
    padding: 50px 0;
  }
} */

/* btn check */

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
  border-radius: 5px;
}

.btn-check:active + .btn-outline-info,
.btn-check:checked + .btn-outline-info,
.btn-outline-info.active,
.btn-outline-info.dropdown-toggle.show,
.btn-outline-info:active {
  color: #fff;
  background-color: #009efb;
  border-color: #0d6efd;
}
.donateTitle {
  font-size: 23px;
  color: #026666;
  font-weight: 500;
  margin-top: 15px;
}

p.donate-description {
  font-size: 18px;
}
.donation label {
  font-size: 16px;
  font-weight: 400;
  color: #4f566a;
}

.collections label {
  font-size: 14px;
  font-weight: 400;
  color: #4f566a;
}

/* summary modal */

.makeStyles-paper-6,
.makeStyles-paper-4,
.makeStyles-paper-8,
.makeStyles-paper-10,
.makeStyles-paper-12,
.makeStyles-paper-14,
.makeStyles-paper-16 {
  border: 0 !important;
  padding: 16px 32px 24px;
  position: absolute;
  max-width: 0 !important;
  min-width: 0 !important;
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 0%), 0px 5px 8px 0px rgb(0 0 0 / 0%),
    0px 1px 14px 0px rgb(0 0 0 / 0%) !important;
  max-height: 0 !important;
  min-height: 0 !important;
  background-color: #ffffff00 !important;
}
.sl-box4 .sl-loader-title {
  color: #469b5a !important;
  padding-top: 25px !important;
}

/* donation */

.consent .wrapper {
  margin: 0;
}

.donation-success h2.successColor {
  font-size: 50px;
  margin: 0;
  background: -webkit-linear-gradient(#8f8523, #dac74e);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.donation-success p {
  font-size: 20px;
  margin: 0;
  color: #c3c7ae;
}
