.bg-white .btn-border {
  background: none;
  color: #888 !important;
  border: 1px solid grey;
}

.btn-border {
  background: none;
  color: #5d9b9b !important;
  border: 1px solid #5d9b9b;
}

.navbar {
  /* padding: 1rem 0; */
  margin-top: 33px;
  font-family: Open Sans, Arial, sans-serif;
}
.navbar a,
.navbar p,
a.nav-link {
  font-size: 18px;
  font-weight: 400;
  color: #5d9b9b;
}

.navbar.bg-white:not(.navbar-transparent) a:not(.dropdown-item):not(.btn) {
  color: rgba(0, 0, 0, 0.6);
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  text-transform: capitalize;
}
.navbar .navbar-nav .nav-link:not(.btn) {
  font-size: 18px;
}

/* Sidebar */
.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  min-height: 100vh !important;
  z-index: 100;
  padding: 48px 0 0;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
}
#sidebar-wrapper {
  min-height: 100vh !important;
  width: 100vw;
  margin-left: -1rem;
  -webkit-transition: margin 0.25s ease-out;
  -moz-transition: margin 0.25s ease-out;
  -o-transition: margin 0.25s ease-out;
  transition: margin 0.25s ease-out;
}
#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

#page-content-wrapper {
  min-width: 0;
  width: 100%;
}

/* header top */

#header-top {
  font-family: Open Sans, Arial, sans-serif;
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  background-color: #026666;
  color: #fff;
}

#header-top .container {
  padding: 0.75em;
  font-weight: 600;
  line-height: 1em;
}
#header-top ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
#header-top ul li {
  display: inline-block;
  padding-right: 15px;
}
#header-top ul li span {
  font-size: 12px;
  line-height: 12px;
}
.dropdown:hover .dropdown-menu {
  display: block;
  margin-top: 0;
}
.sidebar-collapse .navbar .dropdown.show .dropdown-menu,
.sidebar-collapse .navbar .dropdown .dropdown-menu {
  height: auto;
}
.navbar.bg-white:not(.navbar-transparent) .nav-item.active .nav-link:not(.btn),
.navbar.bg-white:not(.navbar-transparent) .nav-item .nav-link:not(.btn):focus,
.navbar.bg-white:not(.navbar-transparent) .nav-item .nav-link:not(.btn):hover,
.navbar.bg-white:not(.navbar-transparent) .nav-item .nav-link:not(.btn):active {
  background-color: rgba(222, 222, 222, 0);
  color: #026666 !important;
}
.navbar.bg-white:not(.navbar-transparent) .nav-item .nav-link:not(.btn):hover {
  opacity: 0.7;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  background-color: rgb(222 222 222 / 0%);
}
