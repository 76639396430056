.wrapper {
  margin-top: 110px;
}
.title {
  font-size: 40px;
  font-weight: bold;
  color: #026666;
}

.btn-green {
  background-color: #026666;
  color: #fff;
}
/* .btn-green:after {
  content: ">";
  position: absolute;
  opacity: 0;
  right: 20%;
  transition: all 300ms ease 0ms;
}

.btn-green:hover {
  padding-right: 50px;
  padding-left: 15px;
  background-color: #ababab;
}

.btn-green:hover:after {
  opacity: 1;
  right: 20px;
} */
.btn-green-alt {
  background-color: #fff;
  color: #026666 !important;
  padding: 7px 30px;
  border: 1px solid #026666;
  border-radius: 4px;
  line-height: 27px;
}
.btn-green-alt:hover {
  background-color: #026666;
  color: #fff !important;
}
.card {
  box-shadow: 0px 5px 10px 2px rgb(94 94 94 / 8%);
}
.card-img {
  width: 100%;
  height: 250px;
  background-size: cover;
}

/* Fundraiser */

.progress {
  height: 0.5rem;
}
.progress-bar {
  background-color: #026666;
}

h4.card-title {
  font-size: 20px;
  font-weight: bold;
  color: #303030;
}

h5.card-title {
  font-size: 20px;
  font-weight: bold;
  color: #002147;
}
p.card-text {
  font-size: 18px;
  color: #4f566a;
}
.card-body p {
  font-size: 14px;
  color: #4f566a;
}
.section-fundraisers .card-body p {
  font-size: 14px;
  color: rgb(0 0 0 / 85%);
  max-height: 40px;
  min-height: 48px;
  overflow: hidden;
}
h3.card-title {
  font-size: 20px;
  font-weight: bold;
  color: #002147;
}
h3.card-title span {
  color: #a3acb8;
  font-weight: 400;
}

/* carousel */
.carousel {
  width: 100%;
}
.carousel .carousel-inner {
  box-shadow: none;
}
.carousel-caption {
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: rgba(0, 0, 0, 0.61);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff;
  width: 100%;
  max-width: 880px;
  bottom: 16%;
  padding-top: 0px;
  padding-bottom: 10px;
}
.carousel-item img {
  background-size: cover;
  background-blend-mode: color-burn;
  width: 100%;
  height: 600px;
}
.carousel h5 {
  font-size: 46px;
  font-weight: bold;
  color: #fff;
}
.carousel .description {
  font-size: 20px;
  font-weight: 400;
  color: #fff;
}
.carousel .message {
  font-size: 20px;
  font-weight: 400;
  font-family: "open-sans";
  color: #ffffff;
  margin: auto 100px;
}

/* Start Fundaraiser */

.start-fundraiser {
  background-color: #f8f8f8;
}
.start-fundraiser .btn {
  font-size: 22px;
  background-color: #026666;
  font-weight: 800;
}

@media only screen and (max-width: 400px) {
  .title {
    font-size: 28px !important;
  }
  .carousel h5 {
    font-size: 25px !important;
  }
  .carousel .description {
    font-size: 14px !important;
  }
  .footer-crowd {
    padding: 50px 0 50px 0;
  }
  .footer,
  .footer h4 {
    margin-top: 40px;
  }
}

@media only screen and (max-width: 768px) {
  .navbar-translate {
    padding: 0 30px 0 20px;
  }

  .section {
    padding: 10px 0;
  }
  .title {
    font-size: 30px;
  }
  .carousel-caption {
    top: 35%;
  }
  .carousel-item img {
    min-height: 40vh;
  }
  .carousel h5 {
    font-size: 30px;
  }
  .carousel .description {
    font-size: 16px;
  }
  .footer-logo img {
    width: 200px;
  }
}

/* Hero */

.crowd-hero h2 {
  background: linear-gradient(90deg, #9b9b9b9e, #02666636);
  color: #ffffff;
  padding: 6px;
  font-size: 45px;
  font-weight: 900;
}
.crowd-hero {
  box-shadow: inset 0 0 0 100vw rgba(0, 0, 0, 0.5);

  /* Basic background styles */
  /* background: url("assets/img/single-campaign.png") center center no-repeat; */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  color: white;
  min-height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Breadcrumb */

.breadcrumb-item.active {
  color: #7daeaa;
  font-size: 14px;
}
.breadcrumb a {
  color: #026666;
  font-size: 14px;
}
.campaign-sumamry h4 {
  color: #026666;
  margin-bottom: 3px;
}

@media screen and (max-width: 400px) {
  .crowd-hero h2 {
    font-size: 32px;
  }
  .start-fundraiser .btn {
    font-size: 14px;
  }
}

.bootstrap-switch .bootstrap-switch-handle-on {
  background-color: #026666;
}
.bootstrap-switch .bootstrap-switch-handle-off {
  background: #f96332;
}
.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-label {
  background-color: rgb(255 255 255);
}
