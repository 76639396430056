.footer,
.footer h4 {
  font-size: 24px;
  font-weight: bold;
  color: #026666 !important;
}

.footer .form-group .form-control {
  padding: 10px 18px 10px 18px;
  color: #0a0a0a !important;
  /* border-color: rgb(255 255 255 / 0%) !important; */
}

/* Footer charity */

.footer-crowd {
  background-color: #fff;
  font-size: 14px;
  font-weight: 400;
  padding: 50px 0 0;
}
.footer-crowd .company-info p {
  font-size: 14px;
  color: #026666;
}
.footer-crowd ul li a {
  color: #026666 !important;
  padding: 0.5rem;
  font-size: 14px;
}

/* narrow footer */
.narrow-footer {
  font-family: Open Sans, Arial, sans-serif;
  font-size: 14px;
  line-height: 24px;
}
.narrow-footer .list-inline {
  display: flex;
  padding: 0;
  margin: 0;
}
.narrow-footer .list-inline li {
  font-weight: 600;
  display: inline-block;
  font-size: 14px;
  padding-right: 5px;
}
.narrow-footer .list-inline a {
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  color: #bbb;
  padding: 0 10px;
  text-decoration: none;
}

@media (max-width: 980px) {
  .narrow-footer {
    text-align: center;
  }
  .narrow-footer .list-inline {
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 400px) {
  .narrow-footer .list-inline a {
    padding: 0 3px;
  }
}
